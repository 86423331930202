import React from 'react'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom';
import Logo from '../image2/logo.svg';
import Hero1 from '../image2/hero-slide-1.jpg'
import Hero2 from '../image2/hero-slide-2.jpg'
import Hero3 from '../image2/hero-slide-3.jpg'
import './Terms.css'
import Navbar from '../components/Navbar';

const Terms = () => {
  return (
    <>
   <Navbar />


      <section className="section hero has-bg-image" aria-label="home" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero-bg.jpg)` }}>
            <div className="container">
              <div className="hero-content">
                <h1 className="h1 hero-title">Terms & Condition</h1>
                
                <div className="btn-wrapper">
                  
                  <a href="tel:+18888119448" className="btn btn-outline">Call Us Toll Free Number</a>
                </div>
              </div>

              <div className="hero-slider" data-slider>
                <div className="slider-inner">
                  <ul className="slider-container" data-slider-container>
                    <li className="slider-item">
                      <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                        <img src={Hero1} width="575" height="550" alt="" className="img-cover" />
                      </figure>
                    </li>
                    <li className="slider-item">
                      <div className="hero-card">
                        <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                          <img src={Hero2} width="575" height="550" alt="hero banner" className="img-cover" />
                        </figure>
                        {/* <button className="play-btn" aria-label="play adex intro">
                          <ion-icon name="play" aria-hidden="true"></ion-icon>
                        </button> */}
                      </div>
                    </li>
                    <li className="slider-item">
                      <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                        <img src={Hero3} width="575" height="550" alt="" className="img-cover" />
                      </figure>
                    </li>
                  </ul>
                </div>
                <button className="slider-btn prev" aria-label="slide to previous" data-slider-prev>
                  <ion-icon name="arrow-back"></ion-icon>
                </button>
                <button className="slider-btn next" aria-label="slide to next" data-slider-next>
                  <ion-icon name="arrow-forward"></ion-icon>
                </button>
              </div>
            </div>
          </section>

          <div className="terms-conditions">
      <div className="container">
        <h1>Terms and Conditions for IT Assist Central</h1>
        

        <h2>1. Introduction</h2>
        <p>Welcome to IT Assist Central. These terms and conditions outline the rules and regulations for the use of our website. By accessing this website, we assume you accept these terms and conditions. Do not continue to use IT Assist Central if you do not agree to take all of the terms and conditions stated on this page.</p>

        <h2>2. License</h2>
        <p>Unless otherwise stated, IT Assist Central and/or its licensors own the intellectual property rights for all material on IT Assist Central. All intellectual property rights are reserved. You may access this from IT Assist Central for your own personal use subjected to restrictions set in these terms and conditions.</p>

        <h2>3. User Comments</h2>
        <p>This Agreement shall begin on the date hereof. Certain parts of this website offer the opportunity for users to post and exchange opinions and information in certain areas of the website. IT Assist Central does not filter, edit, publish, or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of IT Assist Central, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions.</p>

        <h2>4. Hyperlinking to our Content</h2>
        <p>The following organizations may link to our website without prior written approval:</p>
        <ul>
          <li>Government agencies;</li>
          <li>Search engines;</li>
          <li>News organizations;</li>
          <li>Online directory distributors may link to our website in the same manner as they hyperlink to the websites of other listed businesses;</li>
          <li>System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our website.</li>
        </ul>

        <h2>5. iFrames</h2>
        <p>Without prior approval and written permission, you may not create frames around our webpages that alter in any way the visual presentation or appearance of our website.</p>

        <h2>6. Content Liability</h2>
        <p>We shall not be hold responsible for any content that appears on your website. You agree to protect and defend us against all claims that are rising on your website. No link(s) should appear on any website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

        <h2>7. Your Privacy</h2>
        <p>Please read Privacy Policy.</p>

        <h2>8. Reservation of Rights</h2>
        <p>We reserve the right to request that you remove all links or any particular link to our website. You approve to immediately remove all links to our website upon request. We also reserve the right to amen these terms and conditions and its linking policy at any time. By continuously linking to our website, you agree to be bound to and follow these linking terms and conditions.</p>

        <h2>9. Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us:</p>
        <address>
          IT Assist Central<br />
          1498 Bouldercrest Rd SE Apt 118<br />
          Atlanta, GA, 30316, USA<br />
        </address>
        <p>Phone: 8888119448</p>
        <p>Email: info@itassistcentral.com</p>
      </div>
    </div>

    <Footer />
    </>
  )
}

export default Terms