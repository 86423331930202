import React from 'react';
import './PricingPlans.css';

const PricingPlans = () => {
  return (
    <section className="pricing-section" aria-labelledby="pricing-label">
      <div className="container">
        <h2 className="section-title">Choose a plan that best suits your needs</h2>
        <div className="pricing-grid">
          <div className="pricing-card">
            <div className="plan-logo">
              <ion-icon name="people-outline" aria-hidden="true"></ion-icon>
            </div>
            <h3 className="card-title">IT Assist Central Basic</h3>
            <p className="price">$19 <span>per month</span></p>
            <ul className="features-list">
              <li><ion-icon name="checkmark-outline" aria-hidden="true"></ion-icon>Help Desk Support: Unlimited phone, chat, and email support. Device performance and speed tuneups. Software installation and support.</li>
              <li><ion-icon name="checkmark-outline" aria-hidden="true"></ion-icon>Preventative Tune-Ups: Adware/spyware clean up Virus protection.</li>
              <li><ion-icon name="checkmark-outline" aria-hidden="true"></ion-icon>And more support...</li>
            </ul>
            <a href='tel:+18888119448'><button className="subscribe-btn">Subscribe</button></a>
          </div>
          <div className="pricing-card">
            <div className="plan-logo">
              <ion-icon name="business-outline" aria-hidden="true"></ion-icon>
            </div>
            <h3 className="card-title">IT Assist Central Professional</h3>
            <p className="price">$29.99 <span>per month</span></p>
            <ul className="features-list">
              <li><ion-icon name="checkmark-outline" aria-hidden="true"></ion-icon>All basic features</li>
              <li><ion-icon name="checkmark-outline" aria-hidden="true"></ion-icon>Custom Software Platform: Security Suite – antivirus, VPN, Firewall, and more Automatic Backup Employee Monitoring.</li>
              <li><ion-icon name="checkmark-outline" aria-hidden="true"></ion-icon>Remote Workforce Support: New employee onboarding Tracking & computer lockdown at termination Home network and computer security.</li>
              <li><ion-icon name="checkmark-outline" aria-hidden="true"></ion-icon>Backups & Disaster: Recovery Remote data migration. Disaster recovery (RTO & RPO).</li>
              <li><ion-icon name="checkmark-outline" aria-hidden="true"></ion-icon>Security & System Monitoring: Network security scans VPN configuration User training on security best practices.</li>
            </ul>
            <a href='tel:+18888119448'><button className="subscribe-btn">Subscribe</button></a>
            
          </div>
          <div className="pricing-card">
            <div className="plan-logo">
              <ion-icon name="rocket-outline" aria-hidden="true"></ion-icon>
            </div>
            <h3 className="card-title">IT Assist Central Enterprise</h3>
            <p className="price">$49.99 <span>per month</span></p>
            <ul className="features-list">
              <li><ion-icon name="checkmark-outline" aria-hidden="true"></ion-icon>All basic features</li>
              <li><ion-icon name="checkmark-outline" aria-hidden="true"></ion-icon>Advanced Security & System Monitoring: Darkweb scans for leaked information. Email security scans and web content filtering Firewall configuration and updates.</li>
              <li><ion-icon name="checkmark-outline" aria-hidden="true"></ion-icon>Data Security and Privacy Compliance: Guidance to Implement a data security and privacy compliance program Website sign-up/login and privacy policy review Regular data compliance scans for detection of computer/systems vulnerabilities.</li>
              <li><ion-icon name="checkmark-outline" aria-hidden="true"></ion-icon>Dedicated IT Manager</li>
            </ul>
            <a href='tel:+18888119448'><button className="subscribe-btn">Subscribe</button></a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingPlans;
