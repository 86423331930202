import React, { useEffect } from 'react';
import 'ionicons';

const HomeWithJS = () => {

  useEffect(() => {
    const addEventOnElements = (elements, eventType, callback) => {
      for (let i = 0, len = elements.length; i < len; i++) {
        elements[i].addEventListener(eventType, callback);
      }
    };

    const navbar = document.querySelector("[data-navbar]");
    const navTogglers = document.querySelectorAll("[data-nav-toggler]");
    const overlay = document.querySelector("[data-overlay]");

    const toggleNavbar = () => {
      navbar.classList.toggle("active");
      overlay.classList.toggle("active");
      document.body.classList.toggle("nav-active");
    };

    addEventOnElements(navTogglers, "click", toggleNavbar);

    const header = document.querySelector("[data-header]");
    const onScroll = () => {
      if (window.scrollY > 100) {
        header.classList.add("active");
      } else {
        header.classList.remove("active");
      }
    };

    window.addEventListener("scroll", onScroll);

    const sliders = document.querySelectorAll("[data-slider]");

    const initSlider = (currentSlider) => {
      const sliderContainer = currentSlider.querySelector("[data-slider-container]");
      const sliderPrevBtn = currentSlider.querySelector("[data-slider-prev]");
      const sliderNextBtn = currentSlider.querySelector("[data-slider-next]");

      let currentSlidePos = 0;

      const moveSliderItem = () => {
        sliderContainer.style.transform = `translateX(-${sliderContainer.children[currentSlidePos].offsetLeft}px)`;
      };

      const slideNext = () => {
        const slideEnd = currentSlidePos >= sliderContainer.childElementCount - 1;
        if (slideEnd) {
          currentSlidePos = 0;
        } else {
          currentSlidePos++;
        }
        moveSliderItem();
      };

      const slidePrev = () => {
        if (currentSlidePos <= 0) {
          currentSlidePos = sliderContainer.childElementCount - 1;
        } else {
          currentSlidePos--;
        }
        moveSliderItem();
      };

      sliderNextBtn.addEventListener("click", slideNext);
      sliderPrevBtn.addEventListener("click", slidePrev);

      const dontHaveExtraItem = sliderContainer.childElementCount <= 1;
      if (dontHaveExtraItem) {
        sliderNextBtn.style.display = "none";
        sliderPrevBtn.style.display = "none";
      }
    };

    for (let i = 0, len = sliders.length; i < len; i++) {
      initSlider(sliders[i]);
    }

    const accordions = document.querySelectorAll("[data-accordion]");
    let lastActiveAccordion = accordions[0];

    const initAccordion = (currentAccordion) => {
      const accordionBtn = currentAccordion.querySelector("[data-accordion-btn]");

      const expandAccordion = () => {
        if (lastActiveAccordion && lastActiveAccordion !== currentAccordion) {
          lastActiveAccordion.classList.remove("expanded");
        }
        currentAccordion.classList.toggle("expanded");
        lastActiveAccordion = currentAccordion;
      };

      accordionBtn.addEventListener("click", expandAccordion);
    };

    for (let i = 0, len = accordions.length; i < len; i++) {
      initAccordion(accordions[i]);
    }

    return () => {
      window.removeEventListener("scroll", onScroll);
      for (let i = 0, len = navTogglers.length; i < len; i++) {
        navTogglers[i].removeEventListener("click", toggleNavbar);
      }
      for (let i = 0, len = sliders.length; i < len; i++) {
        const currentSlider = sliders[i];
        const sliderPrevBtn = currentSlider.querySelector("[data-slider-prev]");
        const sliderNextBtn = currentSlider.querySelector("[data-slider-next]");
        sliderNextBtn.removeEventListener("click", currentSlider.slideNext);
        sliderPrevBtn.removeEventListener("click", currentSlider.slidePrev);
      }
      for (let i = 0, len = accordions.length; i < len; i++) {
        const currentAccordion = accordions[i];
        const accordionBtn = currentAccordion.querySelector("[data-accordion-btn]");
        accordionBtn.removeEventListener("click", currentAccordion.expandAccordion);
      }
    };
  }, []);

  return (
    <>
      {/* The HTML content of your Home component goes here */}
      {/* You can copy the HTML structure from your existing Home component */}
    </>
  );
};

export default HomeWithJS;
