import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../image2/logo.svg';
import HomeWithJS from '../HomeWithJS';

const Navbar = () => {
  return (
    <>
      <header className="header" data-header>
        <div className="container">
          <Link to="/" className="logo">
            <img src={Logo} width="74" height="24" alt="Adex home" className="logo-light" />
            <img src={Logo} width="74" height="24" alt="Adex home" className="logo-dark" />
          </Link>

          <nav className="navbar" data-navbar>
            <div className="navbar-top">
              <Link to="/" className="logo">
                <img src={Logo} width="74" height="24" alt="Adex home" />
              </Link>
              <button className="nav-close-btn" aria-label="close menu" data-nav-toggler>
                <ion-icon name="close-outline" aria-hidden="true"></ion-icon>
              </button>
            </div>

            <ul className="navbar-list">
              <li><Link to="/" className="navbar-link">Home</Link></li>
              <li><Link to="/about-us" className="navbar-link">About</Link></li>
              <li><Link to="/contact-us" className="navbar-link">Contact Us</Link></li>
            </ul>

            <div className="wrapper">
              <a href="mailto:info@email.com" className="contact-link">info@itassistcentral.com</a>
              <a href="tel:+18888119448" className="contact-link">8888119448</a>
            </div>

            {/* <ul className="social-list">
              <li><a href="#" className="social-link"><ion-icon name="logo-twitter"></ion-icon></a></li>
              <li><a href="#" className="social-link"><ion-icon name="logo-facebook"></ion-icon></a></li>
              <li><a href="#" className="social-link"><ion-icon name="logo-dribbble"></ion-icon></a></li>
              <li><a href="#" className="social-link"><ion-icon name="logo-instagram"></ion-icon></a></li>
              <li><a href="#" className="social-link"><ion-icon name="logo-youtube"></ion-icon></a></li>
            </ul> */}
          </nav>

          <Link to="tel:+18888119448" className="btn btn-primary">Call Us Toll-Free Number</Link>
          <button className="nav-open-btn" aria-label="open menu" data-nav-toggler>
            <ion-icon name="menu-outline" aria-hidden="true"></ion-icon>
          </button>
          <div className="overlay" data-nav-toggler data-overlay></div>
        </div>
      </header>

      <HomeWithJS />
    </>
  );
}

export default Navbar;
