import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Feature from '../image2/feature-banner.png'
import Fts from '../image2/about-banner.png'
import Hero1 from '../image2/hero-slide-1.jpg'
import Hero2 from '../image2/hero-slide-2.jpg'
import Hero3 from '../image2/hero-slide-3.jpg'
import './Contact.css'
import { useNavigate } from 'react-router-dom';

const Contact = () => {

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add form submission logic here if needed
    navigate('/thankyou');
  };

  
  return (
    <>
    <Navbar />

    <section className="section hero has-bg-image" aria-label="home" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero-bg.jpg)` }}>
            <div className="container">
              <div className="hero-content">
                <h1 className="h1 hero-title">Contact Us</h1>
                <p className="hero-text"> Our dedicated team of IT professionals is here to ensure your business runs smoothly and efficiently. From network management to cybersecurity, we offer comprehensive solutions tailored to your unique needs. Let us handle your IT challenges so you can focus on what you do best – growing your business.</p>
                <div className="btn-wrapper">
                  
                  <a href="tel:+18888119448" className="btn btn-outline">Call Us Toll Free Number</a>
                </div>
              </div>

              <div className="hero-slider" data-slider>
                <div className="slider-inner">
                  <ul className="slider-container" data-slider-container>
                    <li className="slider-item">
                      <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                        <img src={Hero1} width="575" height="550" alt="" className="img-cover" />
                      </figure>
                    </li>
                    <li className="slider-item">
                      <div className="hero-card">
                        <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                          <img src={Hero2} width="575" height="550" alt="hero banner" className="img-cover" />
                        </figure>
                        {/* <button className="play-btn" aria-label="play adex intro">
                          <ion-icon name="play" aria-hidden="true"></ion-icon>
                        </button> */}
                      </div>
                    </li>
                    <li className="slider-item">
                      <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                        <img src={Hero3} width="575" height="550" alt="" className="img-cover" />
                      </figure>
                    </li>
                  </ul>
                </div>
                <button className="slider-btn prev" aria-label="slide to previous" data-slider-prev>
                  <ion-icon name="arrow-back"></ion-icon>
                </button>
                <button className="slider-btn next" aria-label="slide to next" data-slider-next>
                  <ion-icon name="arrow-forward"></ion-icon>
                </button>
              </div>
            </div>
          </section>



    <section className="about aaa" aria-labelledby="about-label">
  <div className="container">
    <figure className="about-banner">
      <img src={Fts} width="800" height="580" loading="lazy" alt="about banner" className="w-100" />
    </figure>
    <div className="contact-container">
            <p className="contact-subtitle" id="contact-label">Get in Touch</p>
            <h2 className="contact-title">Contact IT Assist Central</h2>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="contact-form-group">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div className="contact-form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="contact-form-group">
                <label htmlFor="phone">Phone Number</label>
                <input type="tel" id="phone" name="phone" required />
              </div>
              <div className="contact-form-group">
                <label htmlFor="query">Your Query</label>
                <textarea id="query" name="query" rows="4" required></textarea>
              </div>
              <button type="submit" className="contact-btn">Submit</button>
            </form>
          </div>
  </div>
</section>


<section className="section feature" aria-labelledby="feature-label">
  <div className="container">
    <figure className="feature-banner">
      <img src={Feature} width="800" height="531" loading="lazy" alt="feature banner" className="w-100" />
    </figure>
    <div className="contact-content">
      <p className="section-subtitle" id="contact-label">Contact Us</p>
      <h2 className="h2 section-title">Get in Touch with IT Assist Central</h2>
      <p className="section-text">
        We are here to provide you with more information, answer any questions you may have, and create an effective solution for your IT needs.
      </p>
      <ul className="contact-list">
        <li>
          <div className="contact-card">
            <div className="card-icon">
              <ion-icon name="location-outline" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">1498 Bouldercrest Rd SE Apt 118, Atlanta, GA, 30316, USA</span>
          </div>
        </li>
        <li>
          <div className="contact-card">
            <div className="card-icon">
              <ion-icon name="call-outline" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">8888119448</span>
          </div>
        </li>
        <li>
          <div className="contact-card">
            <div className="card-icon">
              <ion-icon name="mail-outline" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">info@itassistcentral.com</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

    <Footer />
    </>
  )
}

export default Contact