import React from 'react'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom';
import Logo from '../image2/logo.svg';
import Hero1 from '../image2/hero-slide-1.jpg'
import Hero2 from '../image2/hero-slide-2.jpg'
import Hero3 from '../image2/hero-slide-3.jpg'
import './Privacy.css'

const Privacy = () => {
  return (
    <>
    <header className="header" data-header>
        <div className="container">
          <Link to="/" className="logo">
            <img src={Logo} width="74" height="24" alt="Adex home" className="logo-light" />
            <img src={Logo} width="74" height="24" alt="Adex home" className="logo-dark" />
          </Link>

          <nav className="navbar" data-navbar>
            <div className="navbar-top">
              <Link to="/" className="logo">
                <img src={Logo} width="74" height="24" alt="Adex home" />
              </Link>
              <button className="nav-close-btn" aria-label="close menu" data-nav-toggler>
                <ion-icon name="close-outline" aria-hidden="true"></ion-icon>
              </button>
            </div>

            <ul className="navbar-list">
              <li><Link to="/" className="navbar-link">Home</Link></li>
              <li><Link to="/about-us" className="navbar-link">About</Link></li>
              <li><Link to="/contact-us" className="navbar-link">Contact Us</Link></li>
            </ul>

            <div className="wrapper">
              <a href="mailto:info@email.com" className="contact-link">info@itassistcentral.com</a>
              <a href="tel:001234567890" className="contact-link">8773134306</a>
            </div>

            {/* <ul className="social-list">
              <li><a href="#" className="social-link"><ion-icon name="logo-twitter"></ion-icon></a></li>
              <li><a href="#" className="social-link"><ion-icon name="logo-facebook"></ion-icon></a></li>
              <li><a href="#" className="social-link"><ion-icon name="logo-dribbble"></ion-icon></a></li>
              <li><a href="#" className="social-link"><ion-icon name="logo-instagram"></ion-icon></a></li>
              <li><a href="#" className="social-link"><ion-icon name="logo-youtube"></ion-icon></a></li>
            </ul> */}
          </nav>

          <Link to="/contact-us" className="btn btn-primary">Call Us Toll-Free Number</Link>
          <button className="nav-open-btn" aria-label="open menu" data-nav-toggler>
            <ion-icon name="menu-outline" aria-hidden="true"></ion-icon>
          </button>
          <div className="overlay" data-nav-toggler data-overlay></div>
        </div>
      </header>


      <section className="section hero has-bg-image" aria-label="home" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero-bg.jpg)` }}>
            <div className="container">
              <div className="hero-content">
                <h1 className="h1 hero-title">Privacy & Policy</h1>
                
                <div className="btn-wrapper">
                  
                  <a href="tel:+18888119448" className="btn btn-outline">Call Us Toll Free Number</a>
                </div>
              </div>

              <div className="hero-slider" data-slider>
                <div className="slider-inner">
                  <ul className="slider-container" data-slider-container>
                    <li className="slider-item">
                      <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                        <img src={Hero1} width="575" height="550" alt="" className="img-cover" />
                      </figure>
                    </li>
                    <li className="slider-item">
                      <div className="hero-card">
                        <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                          <img src={Hero2} width="575" height="550" alt="hero banner" className="img-cover" />
                        </figure>
                        {/* <button className="play-btn" aria-label="play adex intro">
                          <ion-icon name="play" aria-hidden="true"></ion-icon>
                        </button> */}
                      </div>
                    </li>
                    <li className="slider-item">
                      <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                        <img src={Hero3} width="575" height="550" alt="" className="img-cover" />
                      </figure>
                    </li>
                  </ul>
                </div>
                <button className="slider-btn prev" aria-label="slide to previous" data-slider-prev>
                  <ion-icon name="arrow-back"></ion-icon>
                </button>
                <button className="slider-btn next" aria-label="slide to next" data-slider-next>
                  <ion-icon name="arrow-forward"></ion-icon>
                </button>
              </div>
            </div>
          </section>

      <div className="privacy-policy">
      <div className="container">
        <h1>Privacy Policy for IT Assist Central</h1>
        

        <h2>1. Introduction</h2>
        <p>Welcome to IT Assist Central. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy or our practices with regards to your personal information, please contact us at info@itassistcentral.com.</p>

        <h2>2. Information We Collect</h2>
        <p>We collect personal information that you voluntarily provide to us when registering at the website, expressing an interest in obtaining information about us or our products and services, when participating in activities on the website, or otherwise contacting us.</p>

        <h2>3. How We Use Your Information</h2>
        <p>We use personal information collected via our website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.</p>

        <h2>4. Sharing Your Information</h2>
        <p>We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>

        <h2>5. Contact Us</h2>
        <p>If you have questions or comments about this policy, you may contact us by email at info@itassistcentral.com or by post to:</p>
        <address>
          IT Assist Central<br />
          1498 Bouldercrest Rd SE Apt 118<br />
          Atlanta, GA, 30316, USA<br />
        </address>
        <p>Phone: 8888119448</p>
      </div>
    </div>

    <Footer />
    </>
  )
}

export default Privacy