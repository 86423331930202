import React from 'react';
import 'ionicons';
import HomeWithJS from './HomeWithJS';
import Hero1 from './image2/hero-slide-1.jpg'
import Hero2 from './image2/hero-slide-2.jpg'
import Hero3 from './image2/hero-slide-3.jpg'
import About from './image2/about-banner.png'
import Feature from './image2/feature-banner.png'
import PricingPlans from './components/PricingPlans';
import Feature2 from './image2/feature-banner2.webp'
import Footer from './components/Footer';
import Person1 from './image2/person1.jpg'
import Person2 from './image2/person2.jpg'
import Person3 from './image2/person3.jpg'
import Logo from './image2/logo.svg'
import { Link } from 'react-router-dom';
import Navbar from './components/Navbar';

const Home = () => {
  return (
    <>
      <Navbar />

      <main>
        <article>
          <section className="section hero has-bg-image" aria-label="home" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero-bg.jpg)` }}>
            <div className="container">
              <div className="hero-content">
                <h1 className="h1 hero-title">Empowering Your Business with Exceptional IT Assist</h1>
                <p className="hero-text"> Our dedicated team of IT professionals is here to ensure your business runs smoothly and efficiently. From network management to cybersecurity, we offer comprehensive solutions tailored to your unique needs. Let us handle your IT challenges so you can focus on what you do best – growing your business.</p>
                <div className="btn-wrapper">
                  <a href="#about" className="btn btn-primary">Explore Now</a>
                  <a href="tel:+18888119448" className="btn btn-outline">Call Us Toll Free Number</a>
                </div>
              </div>

              <div className="hero-slider" data-slider>
                <div className="slider-inner">
                  <ul className="slider-container" data-slider-container>
                    <li className="slider-item">
                      <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                        <img src={Hero1} width="575" height="550" alt="" className="img-cover" />
                      </figure>
                    </li>
                    <li className="slider-item">
                      <div className="hero-card">
                        <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                          <img src={Hero2} width="575" height="550" alt="hero banner" className="img-cover" />
                        </figure>
                        {/* <button className="play-btn" aria-label="play adex intro">
                          <ion-icon name="play" aria-hidden="true"></ion-icon>
                        </button> */}
                      </div>
                    </li>
                    <li className="slider-item">
                      <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                        <img src={Hero3} width="575" height="550" alt="" className="img-cover" />
                      </figure>
                    </li>
                  </ul>
                </div>
                <button className="slider-btn prev" aria-label="slide to previous" data-slider-prev>
                  <ion-icon name="arrow-back"></ion-icon>
                </button>
                <button className="slider-btn next" aria-label="slide to next" data-slider-next>
                  <ion-icon name="arrow-forward"></ion-icon>
                </button>
              </div>
            </div>
          </section>

<div className='up'>
<section className="stats" aria-label="our stats">
            <div className="container">
              <ul className="stats-card has-bg-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/stats-bg.jpg)` }}>
                <li>
                  <p className="card-text">
                  <ion-icon name="laptop-outline" aria-hidden="true"></ion-icon>
                   
                    <span className="span">Laptop Assist</span>
                  </p>
                </li>
                <li>
                  <p className="card-text">
                  <ion-icon name="desktop-outline" aria-hidden="true"></ion-icon>
                    
                    <span className="span">PC/Mac Assist</span>
                  </p>
                </li>
                <li>
                  <p className="card-text">
                  <ion-icon name="wifi-outline" aria-hidden="true"></ion-icon>
                    
                    <span className="span">Network Assist</span>
                  </p>
                </li>
                <li>
                  <p className="card-text">
                  <ion-icon name="print-outline" aria-hidden="true"></ion-icon>
                    
                    <span className="span">Printer Assist</span>
                  </p>
                </li>
              </ul>
            </div>
          </section>
</div>


          <section id='about' className="about aaa" aria-labelledby="about-label">
  <div className="container">
    <figure className="about-banner">
      <img src={About} width="800" height="580" loading="lazy" alt="about banner" className="w-100" />
    </figure>
    <div className="about-content">
      <p className="section-subtitle" id="about-label">What We Do?</p>
      <h2 className="h2 section-title">With IT Assist Central as your IT partner, you will get:</h2>
      <ul>
        <li className="about-item">
          <div className="accordion-card expanded" data-accordion>
            <h3 className="card-title">
              <button className="accordion-btn" data-accordion-btn>
                <ion-icon name="chevron-down-outline" aria-hidden="true" className="down"></ion-icon>
                <span className="span h5">Proactive Problem Prevention</span>
              </button>
            </h3>
            <p className="accordion-content">Our proactive Assist actively monitors and addresses IT issues before they escalate, ensuring seamless operations and minimal downtime for your business.</p>
          </div>
        </li>
        <li className="about-item">
          <div className="accordion-card" data-accordion>
            <h3 className="card-title">
              <button className="accordion-btn" data-accordion-btn>
                <ion-icon name="chevron-down-outline" aria-hidden="true" className="down"></ion-icon>
                <span className="span h5">Expertise on Demand</span>
              </button>
            </h3>
            <p className="accordion-content">Our seasoned IT professionals are always available, providing rapid responses and effective solutions to any tech challenges you face.</p>
          </div>
        </li>
        <li className="about-item">
          <div className="accordion-card" data-accordion>
            <h3 className="card-title">
              <button className="accordion-btn" data-accordion-btn>
                <ion-icon name="chevron-down-outline" aria-hidden="true" className="down"></ion-icon>
                <span className="span h5">Tailored IT Strategies</span>
              </button>
            </h3>
            <p className="accordion-content">We understand that every business is unique. Our customized IT roadmaps align perfectly with your goals and growth projections.</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>


<section className="section feature" aria-labelledby="feature-label">
  <div className="container">
    <figure className="feature-banner">
      <img src={Feature} width="800" height="531" loading="lazy" alt="feature banner" className="w-100" />
    </figure>
    <div className="feature-content">
      <p className="section-subtitle" id="feature-label">Our Solutions</p>
      <h2 className="h2 section-title">We streamline your IT expenses for optimal control and efficiency.</h2>
      <p className="section-text">
        Our comprehensive IT solutions ensure your business operates smoothly and efficiently, allowing you to focus on growth and success. With our expertise, you can enjoy stress-free IT management and perfect control over your IT expenditures.
      </p>
      <ul className="feature-list">
        <li>
          <div className="feature-card">
            <div className="card-icon">
              <ion-icon name="checkmark" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">Proactive Monitoring to Prevent Issues.</span>
          </div>
        </li>
        <li>
          <div className="feature-card">
            <div className="card-icon">
              <ion-icon name="checkmark" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">Customized IT Solutions Tailored to Your Business.</span>
          </div>
        </li>
        <li>
          <div className="feature-card">
            <div className="card-icon">
              <ion-icon name="checkmark" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">Expert IT Assist Available 24/7.</span>
          </div>
        </li>
        <li>
          <div className="feature-card">
            <div className="card-icon">
              <ion-icon name="checkmark" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">Efficient Remote Assistance to Minimize Downtime.</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>


          <section className="stats" aria-label="our stats">
            <div className="container">
              <ul className="stats-card has-bg-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/stats-bg.jpg)` }}>
                <li>
                  <p className="card-text">
                    <span className="h1">7518</span>
                    <span className="span">Completed Projects</span>
                  </p>
                </li>
                <li>
                  <p className="card-text">
                    <span className="h1">3472</span>
                    <span className="span">Happy Customers</span>
                  </p>
                </li>
                <li>
                  <p className="card-text">
                    <span className="h1">2184</span>
                    <span className="span">Expert Employees</span>
                  </p>
                </li>
                <li>
                  <p className="card-text">
                    <span className="h1">8</span>
                    <span className="span">Awards Won</span>
                  </p>
                </li>
              </ul>
            </div>
          </section>

          <PricingPlans />


          <section className="section service" aria-labelledby="service-label">
  <div className="container">
    <p className="section-subtitle" id="service-label">What We Do?</p>
    <h2 className="h2 section-title">Why Choose Us?</h2>
    <ul className="grid-list">
      <li>
        <div className="service-card">
          <div className="card-icon">
            <ion-icon name="people-outline" aria-hidden="true"></ion-icon>
          </div>
          <h3 className="h4 card-title">Your Very Own Dedicated IT Team</h3>
          <p className="card-text">
            Our dedicated IT team is always ready to provide personalized Assist, ensuring your business runs smoothly and efficiently.
          </p>
          {/* <a href="#" className="btn-text">
            <span className="span">Learn More</span>
            <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
          </a> */}
        </div>
      </li>
      <li>
        <div className="service-card">
          <div className="card-icon">
            <ion-icon name="hardware-chip-outline" aria-hidden="true"></ion-icon>
          </div>
          <h3 className="h4 card-title">Cutting-edge Technology</h3>
          <p className="card-text">
            We leverage the latest technology to ensure that you stay ahead of the curve, providing innovative solutions to your IT needs.
          </p>
          {/* <a href="#" className="btn-text">
            <span className="span">Learn More</span>
            <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
          </a> */}
        </div>
      </li>
      <li>
        <div className="service-card">
          <div className="card-icon">
            <ion-icon name="cloud-outline" aria-hidden="true"></ion-icon>
          </div>
          <h3 className="h4 card-title">Service Automation</h3>
          <p className="card-text">
            Automate your IT services with our advanced automation tools, improving efficiency and reducing manual workloads.
          </p>
          {/* <a href="#" className="btn-text">
            <span className="span">Learn More</span>
            <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
          </a> */}
        </div>
      </li>
      <li>
        <div className="service-card">
          <div className="card-icon">
            <ion-icon name="speedometer-outline" aria-hidden="true"></ion-icon>
          </div>
          <h3 className="h4 card-title">Quick Remote Response</h3>
          <p className="card-text">
            Experience quick and efficient remote IT Assist, minimizing downtime and ensuring your operations continue without interruption.
          </p>
          {/* <a href="#" className="btn-text">
            <span className="span">Learn More</span>
            <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
          </a> */}
        </div>
      </li>
    </ul>
  </div>
</section>


<section className="section feature" aria-labelledby="feature-label">
  <div className="container">
    <figure className="feature-banner">
      <img src={Feature2} width="800" height="531" loading="lazy" alt="feature banner" className="w-100" />
    </figure>
    <div className="feature-content">
      <p className="section-subtitle" id="feature-label">Our Solutions</p>
      <h2 className="h2 section-title">Delivering Reliable It Assist Across 20+ Sectors Nationwide</h2>
      <p className="section-text">
        Our comprehensive IT solutions ensure seamless operations, empowering your business to thrive. Partner with us for efficient IT management and focus on achieving your business goals.
      </p>
      <ul className="feature-list">
        <li>
          <div className="feature-card">
            <div className="card-icon">
              <ion-icon name="checkmark" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">Managed IT Services: Comprehensive management of your IT infrastructure.</span>
          </div>
        </li>
        <li>
          <div className="feature-card">
            <div className="card-icon">
              <ion-icon name="checkmark" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">Digital Marketing: Strategies to enhance your online presence.</span>
          </div>
        </li>
        <li>
          <div className="feature-card">
            <div className="card-icon">
              <ion-icon name="checkmark" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">E-commerce Solutions: Robust platforms for your online store.</span>
          </div>
        </li>
        <li>
          <div className="feature-card">
            <div className="card-icon">
              <ion-icon name="checkmark" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">CRM Solutions: Effective management of customer relationships.</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>


          <section className="section project" aria-labelledby="project-label">
            <div className="container">
              <p className="section-subtitle" id="project-label"></p>
              <h2 className="h2 section-title">Our Techies</h2>
              <ul className="grid-list">
                <li>
                  <div className="project-card">
                    <figure className="card-banner img-holder" style={{ '--width': 560, '--height': 350 }}>
                      <img src={Person1} width="560" height="350" loading="lazy" alt="Ligula tristique quis risus" className="img-cover" />
                    </figure>
                    <div className="card-content">
                      <h3 className="h3"><a href="#" className="card-title">Ethan Walker</a></h3>
                      {/* <p className="card-text">Mauris convallis non ligula non interdum. Gravida vulputate convallis tempus vestibulum cras imperdiet nun eu dolor.</p>
                      <ul className="card-meta-list">
                        <li className="card-meta-item">
                          <ion-icon name="calendar-outline" aria-hidden="true"></ion-icon>
                          <time className="meta-text" dateTime="2022-04-14">14 Apr 2022</time>
                        </li>
                        <li className="card-meta-item">
                          <ion-icon name="document-text-outline" aria-hidden="true"></ion-icon>
                          <span className="meta-text">Coding</span>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="project-card">
                    <figure className="card-banner img-holder" style={{ '--width': 560, '--height': 350 }}>
                      <img src={Person2} width="560" height="350" loading="lazy" alt="Nullam id dolor elit id nibh" className="img-cover" />
                    </figure>
                    <div className="card-content">
                      <h3 className="h3"><a href="#" className="card-title">Brandon Hughes</a></h3>
                      {/* <p className="card-text">Mauris convallis non ligula non interdum. Gravida vulputate convallis tempus vestibulum cras imperdiet nun eu dolor.</p>
                      <ul className="card-meta-list">
                        <li className="card-meta-item">
                          <ion-icon name="calendar-outline" aria-hidden="true"></ion-icon>
                          <time className="meta-text" dateTime="2022-03-29">29 Mar 2022</time>
                        </li>
                        <li className="card-meta-item">
                          <ion-icon name="document-text-outline" aria-hidden="true"></ion-icon>
                          <span className="meta-text">Workspace</span>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="project-card">
                    <figure className="card-banner img-holder" style={{ '--width': 560, '--height': 350 }}>
                      <img src={Person3} width="560" height="350" loading="lazy" alt="Ultricies fusce porta elit" className="img-cover" />
                    </figure>
                    <div className="card-content">
                      <h3 className="h3"><a href="#" className="card-title">David Wilson</a></h3>
                      {/* <p className="card-text">Mauris convallis non ligula non interdum. Gravida vulputate convallis tempus vestibulum cras imperdiet nun eu dolor.</p>
                      <ul className="card-meta-list">
                        <li className="card-meta-item">
                          <ion-icon name="calendar-outline" aria-hidden="true"></ion-icon>
                          <time className="meta-text" dateTime="2022-02-26">26 Feb 2022</time>
                        </li>
                        <li className="card-meta-item">
                          <ion-icon name="document-text-outline" aria-hidden="true"></ion-icon>
                          <span className="meta-text">Meeting</span>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>

          {/* <section className="cta" aria-label="call to action">
            <div className="container">
              <h2 className="h2 section-title">Join our community by using our services and grow your business.</h2>
              <a href="#" className="btn btn-primary">Try it For Free</a>
            </div>
          </section> */}
        </article>
      </main>

      <Footer />

      <HomeWithJS />
    </>
  );
};

export default Home;
