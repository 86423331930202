import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../image2/logo.svg';


const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container grid-list">
          <div className="footer-brand">
            <Link to="/" className="logo">
              <img src={Logo} width="74" height="24" alt="Adex home" />
            </Link>
            <p className="footer-text">&copy; 2024 IT Assist Central <br /> All rights reserved.</p>
          </div>

          <ul className="footer-list">
            <li><p className="h4 footer-list-title">Get in Touch</p></li>
            <li><address className="footer-text">Address: 1498 Bouldercrest Rd SE Apt 118, Atlanta, GA, 30316, USA</address></li>
            <li><a href="mailto:info@itassistcentral.com" className="footer-link">info@itassistcentral.com</a></li>
            <li><a href="tel:+18773134306" className="footer-link">8773134306</a></li>
          </ul>

          <ul className="footer-list">
            <li><p className="h4 footer-list-title">About Us</p></li>
            <li><Link to="/contact-us" className="footer-link">Contact Us</Link></li>
            <li><Link to="/terms-contions" className="footer-link">Terms of Use</Link></li>
            <li><Link to="/privacy-policy" className="footer-link">Privacy Policy</Link></li>
          </ul>
        </div>
      </footer>
    </>
  );
}

export default Footer;
