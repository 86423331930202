import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Feature from '../image2/feature-banner.png'
import Fts from '../image2/about-banner.png'
import Hero1 from '../image2/hero-slide-1.jpg'
import Hero2 from '../image2/hero-slide-2.jpg'
import Hero3 from '../image2/hero-slide-3.jpg'

const About = () => {
  return (
    <>
    <Navbar />

    <section className="section hero has-bg-image" aria-label="home" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero-bg.jpg)` }}>
            <div className="container">
              <div className="hero-content">
                <h1 className="h1 hero-title">About Us</h1>
                <p className="hero-text"> Our dedicated team of IT professionals is here to ensure your business runs smoothly and efficiently. From network management to cybersecurity, we offer comprehensive solutions tailored to your unique needs. Let us handle your IT challenges so you can focus on what you do best – growing your business.</p>
                <div className="btn-wrapper">
                  
                  <a href="tel:+18888119448" className="btn btn-outline">Call Us Toll Free Number</a>
                </div>
              </div>

              <div className="hero-slider" data-slider>
                <div className="slider-inner">
                  <ul className="slider-container" data-slider-container>
                    <li className="slider-item">
                      <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                        <img src={Hero1} width="575" height="550" alt="" className="img-cover" />
                      </figure>
                    </li>
                    <li className="slider-item">
                      <div className="hero-card">
                        <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                          <img src={Hero2} width="575" height="550" alt="hero banner" className="img-cover" />
                        </figure>
                        {/* <button className="play-btn" aria-label="play adex intro">
                          <ion-icon name="play" aria-hidden="true"></ion-icon>
                        </button> */}
                      </div>
                    </li>
                    <li className="slider-item">
                      <figure className="img-holder" style={{ '--width': 575, '--height': 550 }}>
                        <img src={Hero3} width="575" height="550" alt="" className="img-cover" />
                      </figure>
                    </li>
                  </ul>
                </div>
                <button className="slider-btn prev" aria-label="slide to previous" data-slider-prev>
                  <ion-icon name="arrow-back"></ion-icon>
                </button>
                <button className="slider-btn next" aria-label="slide to next" data-slider-next>
                  <ion-icon name="arrow-forward"></ion-icon>
                </button>
              </div>
            </div>
          </section>



    <section className="about aaa" aria-labelledby="about-label">
  <div className="container">
    <figure className="about-banner">
      <img src={Fts} width="800" height="580" loading="lazy" alt="about banner" className="w-100" />
    </figure>
    <div className="about-content">
      <p className="section-subtitle" id="about-label">What We Do?</p>
      <h2 className="h2 section-title">With IT Assist Central as your IT partner, you will get:</h2>
      <ul>
        <li className="about-item">
          <div className="accordion-card expanded" data-accordion>
            <h3 className="card-title">
              <button className="accordion-btn" data-accordion-btn>
                <ion-icon name="chevron-down-outline" aria-hidden="true" className="down"></ion-icon>
                <span className="span h5">Proactive Problem Prevention</span>
              </button>
            </h3>
            <p className="accordion-content">Our proactive assist actively monitors and addresses IT issues before they escalate, ensuring seamless operations and minimal downtime for your business.</p>
          </div>
        </li>
        <li className="about-item">
          <div className="accordion-card" data-accordion>
            <h3 className="card-title">
              <button className="accordion-btn" data-accordion-btn>
                <ion-icon name="chevron-down-outline" aria-hidden="true" className="down"></ion-icon>
                <span className="span h5">Expertise on Demand</span>
              </button>
            </h3>
            <p className="accordion-content">Our seasoned IT professionals are always available, providing rapid responses and effective solutions to any tech challenges you face.</p>
          </div>
        </li>
        <li className="about-item">
          <div className="accordion-card" data-accordion>
            <h3 className="card-title">
              <button className="accordion-btn" data-accordion-btn>
                <ion-icon name="chevron-down-outline" aria-hidden="true" className="down"></ion-icon>
                <span className="span h5">Tailored IT Strategies</span>
              </button>
            </h3>
            <p className="accordion-content">We understand that every business is unique. Our customized IT roadmaps align perfectly with your goals and growth projections.</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

<section className="section feature" aria-labelledby="feature-label">
  <div className="container">
    <figure className="feature-banner">
      <img src={Feature} width="800" height="531" loading="lazy" alt="feature banner" className="w-100" />
    </figure>
    <div className="feature-content">
      <p className="section-subtitle" id="feature-label">Our Solutions</p>
      <h2 className="h2 section-title">We streamline your IT expenses for optimal control and efficiency.</h2>
      <p className="section-text">
        Our comprehensive IT solutions ensure your business operates smoothly and efficiently, allowing you to focus on growth and success. With our expertise, you can enjoy stress-free IT management and perfect control over your IT expenditures.
      </p>
      <ul className="feature-list">
        <li>
          <div className="feature-card">
            <div className="card-icon">
              <ion-icon name="checkmark" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">Proactive Monitoring to Prevent Issues.</span>
          </div>
        </li>
        <li>
          <div className="feature-card">
            <div className="card-icon">
              <ion-icon name="checkmark" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">Customized IT Solutions Tailored to Your Business.</span>
          </div>
        </li>
        <li>
          <div className="feature-card">
            <div className="card-icon">
              <ion-icon name="checkmark" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">Expert IT assist Available 24/7.</span>
          </div>
        </li>
        <li>
          <div className="feature-card">
            <div className="card-icon">
              <ion-icon name="checkmark" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">Efficient Remote Assistance to Minimize Downtime.</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

    <Footer />
    </>
  )
}

export default About