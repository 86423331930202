import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import './Thankyou.css';

const Thankyou = () => {
  return (
    <>
      <Navbar />
      <section className="thankyou-section-unique">
        <div className="thankyou-container-unique">
          <div className="thankyou-content-unique">
            <h1 className="thankyou-title-unique">Thank You for Contacting IT Assist Central</h1>
            <p className="thankyou-text-unique">
              We have received your query and our team will get back to you shortly. Your satisfaction is our priority, and we are here to assist you with all your IT needs.
            </p>
            <div className="thankyou-info-unique">
              <p><strong>Address:</strong> 1498 Bouldercrest Rd SE Apt 118, Atlanta, GA, 30316, USA</p>
              <p><strong>Phone:</strong> <a href="tel:+18888119448">8888119448</a></p>
              <p><strong>Email:</strong> <a href="mailto:info@itassistcentral.com">info@itassistcentral.com</a></p>
            </div>
            <div className="thankyou-actions-unique">
              <Link to="/" className="btn-primary-unique">Return to Home</Link>
              <Link to="tel:+18888119448" className="btn-outline-unique">Contact Us Toll Free</Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Thankyou;
